<template>
  <transition>
    <div v-if="modalIsOpen"
         class="modal-wrapper"
         @click="toggleModal"
    >
      <div class="post-modal" @click.stop="">
        <img class="left-icon"
             :class="{'left-icon_disabled': disabled === 'left'}"
             src="../../../assets/icons/left-icon.png"
             @click="swapPost('left')"
        >
        <img
            class="right-icon"
            :class="{'right-icon_disabled': disabled === 'right'}"
            src="../../../assets/icons/left-icon.png"
            @click="swapPost('right')"
        >
        <post-component
            class="post-modal__post"
            :date="post.date"
            :links="post.links"
            :video="post.video"
        >
          <template v-slot:text>
            <p class="pre-formated">{{post.text}}</p>
          </template>
          <template v-slot:photo-collection>
            <photo-collection
                v-if="post.photoAmount"
                :directory="post.photoDirectory"
                :amount="post.photoAmount"
                :in-post="true"
            ></photo-collection>
          </template>
        </post-component>
      </div>
    </div>
  </transition>
</template>

<script setup>
import {watch} from "vue";
import {useRouter} from "vue-router";

import PostComponent from "@/components/MainContent/Posts/PostComponent.vue";
import PhotoCollection from "@/components/MainContent/Photo/PhotoCollection.vue";

const props = defineProps({
  post: {
    type: Object,
  },
  postId: {
    type: String,
    default: '0'
  },
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: String,
    default: '',
  }
});

const emit = defineEmits(['toggle-modal', 'swap-post']);

const router = useRouter();

function toggleModal() {
  emit('toggle-modal');
}

function swapPost(direction) {
  if (props.disabled == 'left' && direction == 'left') return;
  if (props.disabled == 'right' && direction == 'right') return;
  emit('swap-post', direction);
}

watch(() => props.modalIsOpen, (val) => {
  if (val) {
    document.body.style.overflowY = 'hidden';
    router.push({name: 'news', query: {post: props.postId}})
  } else {
    document.body.style.overflowY = 'auto';
    router.push({name: 'news'})
  }
})
</script>

<style lang="scss">
.modal-wrapper {
  position:fixed;
  top:0;
  height: 100%;
  left:0;
  right: calc(100% - 100vw);
  overflow: hidden;
  background-color: rgb(0, 0, 0, 85%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.post-modal {
  position: relative;
  background-color: white;
  border-radius: 10px;
  height: fit-content;
  max-height: 80%;
  width: 60%;
  padding: 10px 0;
  overflow-y: scroll;
  overflow-x: hidden;

  .post-component {
    margin-top: 0;

    .photo-container {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }
}

.post-modal__post {
  margin: auto;
}

.cross-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.left-icon, .right-icon {
  padding: 2px 12px 2px 12px;
  height: 70px;
  width: auto;
  position: fixed;
  top: 45%;
  cursor: pointer;
  &_disabled {
    opacity: 50%;
    cursor: default;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 20%);
  }
  &_disabled:hover {
    background-color: rgb(0, 0, 0, 0%);
  }
}

.right-icon {
  right: 5%;
  rotate: 180deg;
}

.left-icon {
  left: 5%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1280px) {
  .post-modal {

    .post-component {

      iframe {
        width: 400px !important;
        height: 300px !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .post-modal {
    width: 80%;
    .left-icon, .right-icon {
      padding: 2px 12px 2px 12px;
      height: 40px;
    }

    .left-icon {
      left: 1%;
    }

    .right-icon {
      right: 1%;
    }

    .post-component {

      .photo-container {
        grid-template-columns: 1fr 1fr !important;
      }

      iframe {
        width: 320px !important;
        height: 240px !important;
      }
    }
  }
}


@media screen and (max-width: 620px) {
  .post-modal {

    .post-component {

      iframe {
        width: 256px !important;
        height: 192px !important;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .post-modal {

    .post-component {

      .photo-container {
        grid-template-columns: 1fr !important;
      }
    }
  }
}
</style>