import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/AboutView.vue'
import MainView from "@/views/MainView.vue";
import ForParents from "@/views/ForParents.vue";
import ForKids from "@/views/ForKids.vue";
import MethodicalMaterials from "@/views/MethodicalMaterials.vue";
import PhotoGallery from "@/views/PhotoGallery.vue";
import FeedBack from "@/views/FeedBack.vue";

const routes = [
  {
    path: '/',
    name: 'about',
    component: AboutView
  },
  {
    path: '/news',
    name: 'news',
    component: MainView
  },
  {
    path: '/for_teachers_and_parents',
    name: 'forParents',
    component: ForParents
  },
  {
    path: '/for_kids',
    name: 'forKids',
    component: ForKids
  },
  {
    path: '/methodical_materials',
    name: 'methodicalMaterials',
    component: MethodicalMaterials
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: PhotoGallery
  },
  {
    path: '/Feedback',
    name: 'feedback',
    component: FeedBack
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
