<template>
  <router-link  class="navigational-item" :to="mutatedLink" >{{$t(link)}}</router-link>
</template>

<script setup>
import {defineProps} from "vue";
const props = defineProps({link: String})
const mutatedLink = props.link === 'about' ? '/' : props.link;
</script>

<style lang="scss" scoped>
.navigational-item {
  display: flex;
  flex-grow: 1;
  background-color: #0f75bd;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  &:hover {
    background-color: #22409a;
  }
}

.router-link-exact-active {
  background-color: #22409a;
}
</style>